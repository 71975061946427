import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { LogService } from '../../core/log/log.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'dottnet-consent-management',
	standalone: true,
	templateUrl: './consent-management.component.html',
	styleUrls: ['./consent-management.component.scss'],
})
export class ConsentManagementComponent implements OnInit {
	constructor(private logService: LogService) {}

	ngOnInit(): void {
		this.logService.info('ConsentManagementComponent');
	}
}
